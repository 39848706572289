exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-docs-js": () => import("./../../../src/pages/page-docs.js" /* webpackChunkName: "component---src-pages-page-docs-js" */),
  "component---src-pages-page-jobs-js": () => import("./../../../src/pages/page-jobs.js" /* webpackChunkName: "component---src-pages-page-jobs-js" */),
  "component---src-pages-page-market-js": () => import("./../../../src/pages/page-market.js" /* webpackChunkName: "component---src-pages-page-market-js" */),
  "component---src-pages-page-people-js": () => import("./../../../src/pages/page-people.js" /* webpackChunkName: "component---src-pages-page-people-js" */),
  "component---src-pages-page-project-js": () => import("./../../../src/pages/page-project.js" /* webpackChunkName: "component---src-pages-page-project-js" */),
  "component---src-pages-page-school-active-courses-js": () => import("./../../../src/pages/page-school-active-courses.js" /* webpackChunkName: "component---src-pages-page-school-active-courses-js" */),
  "component---src-pages-page-school-available-courses-js": () => import("./../../../src/pages/page-school-available-courses.js" /* webpackChunkName: "component---src-pages-page-school-available-courses-js" */),
  "component---src-pages-page-school-finished-courses-js": () => import("./../../../src/pages/page-school-finished-courses.js" /* webpackChunkName: "component---src-pages-page-school-finished-courses-js" */),
  "component---src-pages-page-school-js": () => import("./../../../src/pages/page-school.js" /* webpackChunkName: "component---src-pages-page-school-js" */),
  "component---src-pages-page-settings-js": () => import("./../../../src/pages/page-settings.js" /* webpackChunkName: "component---src-pages-page-settings-js" */),
  "component---src-pages-page-signup-js": () => import("./../../../src/pages/page-signup.js" /* webpackChunkName: "component---src-pages-page-signup-js" */),
  "component---src-pages-page-team-js": () => import("./../../../src/pages/page-team.js" /* webpackChunkName: "component---src-pages-page-team-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

